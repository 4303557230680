<template>
  <div class="container-fliud">
    <div class="row">
      <div class="col-12 m-0 p-0">
        <div class="row justify-content-end m-0 p-0">
          <div class="col-12 col-xl-3 mb-3">
            <b-form-file
              v-model="file1"
              :state="Boolean(file1)"
              size="sm"
              placeholder="เลือกไฟล์ที่ต้องการอัพโหลด"
              drop-placeholder="Drop file here..."
              @change="onChange"
            ></b-form-file>
          </div>
          <!-- </div> -->
          <div class="col-6 col-xl-3 text-xl-right">
            <button
              type="button"
              class="btn btn-sm bt-bnb mx-1"
              :disabled="!file1"
              @click="Upload()"
            >
              บันทึกข้อมูล
            </button>
            <button
              type="button"
              class="btn btn-sm bt mx-1"
              :disabled="!file1"
              @click="Clear()"
            >
              ยกเลิก
            </button>
            <button type="button" class="btn btn-sm bt-main mx-1">
              <a :href="file" download class="text-white"> ไฟล์ตัวอย่าง</a>
            </button>
          </div>
        </div>
      </div>
      <div class="row col-12">
        <div class="col-12  ">
          <p style="font-weight: bold;">
            จำนวน&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ &nbsp; &nbsp;
          </p>
        </div>
      </div>
      <div class="col-12">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          :current-page="page_num"
          :per-page="page_size"
          :tbody-tr-class="rowClass"
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
          <template #cell(Status)="row">
            <span
              :class="[row.item.Status == '1' ? 'badge-main' : 'badge-twd']"
              class="badge"
              >{{ row.item.Status == "0" ? "No" : "Yes" }}</span
            >
          </template>
        </b-table>
      </div>
      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
            <select class="custom-select" v-model="page_size">
              <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                {{ e }}
              </option>
            </select>
          </div>
          <div class="col-6 col-xl-3">
            <b-pagination
              v-model="page_num"
              :total-rows="totalRows"
              :per-page="page_size"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Upload",
  data() {
    return {
      file: require("@/files/Image360Exam.xlsx"),
      file1: null,
      totalRows: 0,
      items: null,
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      fields: [
        { key: "SkCode", label: "SkCode" },
        { key: "Status", label: "Status" },
        // { key: "Image360", label: "Image360" },
        {
          key: "Action",
          label: "",
        },
      ],
    };
  },
  methods: {
    async Upload() {
      try {
        if (this.items.length == 0) throw `กรุณาเลือกไฟล์ที่ต้องการอัพโหลด`;
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          const data = { jsnPimImage360: this.items };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `manage/uploadpimimage360`,
            data,
            1
          );
          this.$serviceMain.showSuccessAlert(
            this,
            "อัพโหลดข้อมูลสำเร็จ / Complete"
          );
          await this.$store.dispatch("Image360Actions/getPimImage360");
          this.ClearTable();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async Clear() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการยกเลิกหรือไม่? / Do you want to cancel?"
      );
      if (confirm) {
        this.ClearTable();
      }
    },
    async ClearTable() {
      this.file1 = null;
      this.items = null;
      this.totalRows = 0;
      this.numerror = 0;
      this.msserror = [];
    },
    async onChange(event) {
      try {
        this.file = event.target.files ? event.target.files[0] : null;
        let rows = await this.$serviceMain.ReadExcel(this.file);

        var jsnImage360 = rows.map((row) => ({
          SkCode: String(row.SkCode).trim(),
          Status: String(row.Status).trim(),
          Image360: String(row.Image360).trim(),
        }));

        let findBlanks = jsnImage360.find(
          (itm) =>
            itm.SkCode == "" ||
            itm.SkCode == "undefined" ||
            itm.Status == "" ||
            itm.Status == "undefined"
        );
        if (findBlanks) {
          throw `กรุณาตรวจสอบไฟล์ SkCode และ Status ไม่สามารถว่างได้`;
        }
        this.items = jsnImage360;
        this.totalRows = jsnImage360.length;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
    },
  },
};
</script>

<style></style>
