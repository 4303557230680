<template>
  <div class="container-fliud">
    <div class="row">
      <div class="row w-100 ">
        <div class="col-12 col-xl-6 mb-3">
          <div class="input-group input-group-sm   ">
            <div class="input-group-prepend input-group-sm">
              <select class="custom-select" v-model="mode">
                <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                  {{ e.name }}
                </option>
              </select>
            </div>
            <input
              type="search"
              class="form-control form-control-sm"
              placeholder="ค้นหา / Seach"
              v-model="find"
              v-on:keyup.enter="Search()"
            />
            <div class="input-group-append">
              <button class="btn btn-sm bt-main" @click="Search()">
                ค้นหา
              </button>
            </div>
          </div>
        </div>
        <div class="col-12  ">
          <p style="font-weight: bold;">
            จำนวน&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ
          </p>
        </div>
      </div>
      <div class="col-12">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          :current-page="page_num"
          :per-page="page_size"
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
          <template #cell(Active)="row">
            <span
              :class="[row.item.Active == 1 ? 'badge-main' : 'badge-twd']"
              class="badge"
              >{{ row.item.Active == 0 ? "No" : "Yes" }}</span
            >
          </template>
          <template #cell(Error)="row">
            <span class="text-twd font-600">{{ row.item.Error }}</span>
          </template>
        </b-table>
      </div>
      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
            <select class="custom-select" v-model="page_size">
              <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                {{ e }}
              </option>
            </select>
          </div>
          <div class="col-6 col-xl-3">
            <b-pagination
              v-model="page_num"
              :total-rows="totalRows"
              :per-page="page_size"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorShowOnWeb",

  data() {
    return {
      pdata: "",
      remodal: Date.now() + "d",
      mode: "SkCode",
      select_mode: [
        { name: "ค้นหาด้วย SKU", key: "SkCode" },
        { name: "ค้นหาด้วย Product Name", key: "prNameTH" },
      ],
      totalRows: 0,
      find: "",
      items: [],
      itemlist: [],
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      fields: [
        {
          key: "SkCode",
          label: "SkuCode",
          sortable: true,
          thStyle: { width: "10%" },
        },
        { key: "prNameTH", label: "Product Nmae", thStyle: { width: "35%" } },
        {
          key: "Active",
          label: "Active",
          sortable: true,
          class: "text-center",
        },
        {
          key: "Error",
          label: "Error",
          thStyle: { width: "20%" },
          sortable: true,
        },
        { key: "UpdateBy", label: "UpdateBy", sortable: true },
        {
          key: "UpdateDate",
          label: "Last Update",
          class: "text-center",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "Action",
          label: "",
          class: "text-right",
          thStyle: { width: "10%" },
        },
      ],
    };
  },
  watch: {
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
    GET_Error: function(v) {
      this.getdata();
    },
  },
  computed: {
    GET_Error: function() {
      return this.$store.getters["Image360Actions/GET_Error"];
    },
  },
  mounted() {
    this.getdata();
  },
  methods: {
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    Search() {
      var results = [];
      if (this.find == "") {
        // this.$emit("getProductByRemark");
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
    async ShowOnWeb(data) {
      let { SkCode, Active } = data;
      var message = "";
      var UpdateStatus = "";
      if (Active == "1") {
        UpdateStatus = "0";
        message = `คุณต้องการยกเลิกการแสดงภาพ360 ของ Sku : ${SkCode} ใช่หรือไม่ ?`;
      } else {
        UpdateStatus = "1";
        message = `คุณต้องการแสดงภาพ 360 องศาของ Sku : ${SkCode} ใช่หรือไม่ ? `;
      }
      let confirm = await this.$serviceMain.showConfirmAlert(this, message);
      if (confirm) {
        let data = { SkCode: SkCode, Status: UpdateStatus };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `manage/updatepimimage360`,
          data,
          1
        );
        this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        await this.$store.dispatch("Image360Actions/getPimImage360");
      }
    },
    async getdata() {
      try {
        let getAPI = await this.$store.getters["Image360Actions/GET_Error"];
        if (getAPI !== null) {
          this.items = getAPI;
          this.itemlist = getAPI;
          this.totalRows = getAPI.length;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style></style>
